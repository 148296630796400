import React from "react";
import { connect, DispatchProp } from "react-redux";

import {
  BeveledBoxSvg317x89,
  BoxBackground,
} from "@dndbeyond/character-components/es";
import {
  rulesEngineSelectors,
  characterActions,
  CharacterHitPointInfo,
  CharacterUtils,
  Constants,
  HelperUtils,
  RuleData,
  RuleDataUtils,
  CharacterTheme,
} from "@dndbeyond/character-rules-engine/es";

import { useSidebar } from "~/contexts/Sidebar";
import { PaneInfo } from "~/contexts/Sidebar/Sidebar";
import { PaneComponentEnum } from "~/subApps/sheet/components/Sidebar/types";

import { ThemeButton } from "../../../Shared/components/common/Button";
import {
  HP_DAMAGE_TAKEN_VALUE,
  HP_TEMP_VALUE,
} from "../../../Shared/constants/App";
import { appEnvSelectors } from "../../../Shared/selectors";
import { SheetAppState } from "../../typings";

interface Props extends DispatchProp {
  hitPointInfo: CharacterHitPointInfo;
  ruleData: RuleData;
  fails: number;
  successes: number;
  deathsavesFailLabel: string;
  deathsavesSuccessLabel: string;
  deathCause: Constants.DeathCauseEnum;
  isReadonly: boolean;
  theme: CharacterTheme;
  paneHistoryStart: PaneInfo["paneHistoryStart"];
}
interface State {
  isAdjusterEditorVisible: boolean;
  isAdjusterFocused: boolean;
  isAdjusterHovered: boolean;
  isCurrentEditorVisible: boolean;
  isTempEditorVisible: boolean;
  adjustmentAmount: number | null;
  currentAmount: number | null;
  tempAmount: number | null;
}
class HealthSummary extends React.PureComponent<Props, State> {
  static defaultProps = {
    deathsavesFailLabel: "Failure",
    deathsavesSuccessLabel: "Success",
  };

  currentEditorRef = React.createRef<HTMLInputElement>();
  tempEditorRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      isAdjusterEditorVisible: true,
      adjustmentAmount: null,
      isAdjusterFocused: false,
      isAdjusterHovered: false,
      isCurrentEditorVisible: false,
      currentAmount: props.hitPointInfo.remainingHp,
      isTempEditorVisible: false,
      tempAmount: props.hitPointInfo.tempHp,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.hitPointInfo.remainingHp !==
        prevProps.hitPointInfo.remainingHp ||
      this.props.hitPointInfo.tempHp !== prevProps.hitPointInfo.tempHp
    ) {
      this.setState({
        currentAmount: this.props.hitPointInfo.remainingHp,
        tempAmount: this.props.hitPointInfo.tempHp,
      });
    }
  }

  componentDidMount() {
    window.addEventListener("wheel", this.handleWheel, { passive: false });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("wheel", this.handleWheel);
    window.removeEventListener("scroll", this.handleScroll);
  }

  isAdjusterInputWheelable = (): boolean => {
    const { isAdjusterHovered, isAdjusterFocused } = this.state;

    return isAdjusterHovered || isAdjusterFocused;
  };

  isCurrentInputWheelable = (): boolean => {
    const { isCurrentEditorVisible } = this.state;

    return isCurrentEditorVisible;
  };

  isTempInputWheelable = (): boolean => {
    const { isTempEditorVisible } = this.state;

    return isTempEditorVisible;
  };

  handleScroll = (evt: Event): void => {
    if (
      this.isAdjusterInputWheelable() ||
      this.isCurrentInputWheelable() ||
      this.isTempInputWheelable()
    ) {
      evt.preventDefault();
    }
  };

  handleWheel = (evt: WheelEvent): void => {
    let direction = evt.deltaY < 0 ? 1 : -1;

    if (this.isAdjusterInputWheelable()) {
      evt.preventDefault();
      this.handleAdjusterWheel(direction);
    }

    if (this.isCurrentInputWheelable()) {
      evt.preventDefault();
      this.handleCurrentWheel(direction);
    }

    if (this.isTempInputWheelable()) {
      evt.preventDefault();
      this.handleTempWheel(direction);
    }
  };

  handleSummaryClick = (evt: React.MouseEvent): void => {
    const { paneHistoryStart, isReadonly } = this.props;

    if (!isReadonly) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      paneHistoryStart(PaneComponentEnum.HEALTH_MANAGE);
    }
  };

  handleAdjusterEmptyClick = (evt: React.MouseEvent): void => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    this.setState({
      isAdjusterEditorVisible: true,
    });
  };

  handleAdjusterClick = (evt: React.MouseEvent): void => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
  };

  handleAdjusterMouseOver = (evt: React.MouseEvent): void => {
    this.setState({
      isAdjusterHovered: true,
    });
  };

  handleAdjusterMouseOut = (evt: React.MouseEvent): void => {
    this.setState({
      isAdjusterHovered: false,
    });
  };

  handleAdjusterInputChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({
      adjustmentAmount: HelperUtils.parseInputInt(evt.target.value),
    });
  };

  handleAdjusterWheel = (direction: number): void => {
    const { hitPointInfo, isReadonly } = this.props;

    if (!isReadonly) {
      this.setState((prevState: State) => {
        let adjustmentAmount: number =
          (prevState.adjustmentAmount ?? 0) + direction;
        adjustmentAmount = HelperUtils.clampInt(
          adjustmentAmount,
          0,
          hitPointInfo.totalHp * 2
        );
        return {
          adjustmentAmount,
        };
      });
    }
  };

  handleCurrentWheel = (direction: number): void => {
    const { hitPointInfo } = this.props;

    this.setState((prevState: State) => {
      let currentAmount: number = (prevState.currentAmount ?? 0) + direction;
      currentAmount = HelperUtils.clampInt(
        currentAmount,
        0,
        hitPointInfo.totalHp
      );
      return {
        currentAmount,
      };
    });
  };

  handleTempWheel = (direction: number): void => {
    this.setState((prevState: State) => {
      let tempAmount: number =
        (prevState.tempAmount ?? HP_TEMP_VALUE.MIN) + direction;
      tempAmount = HelperUtils.clampInt(
        tempAmount,
        HP_TEMP_VALUE.MIN,
        HP_TEMP_VALUE.MAX
      );
      return {
        tempAmount,
      };
    });
  };

  handleAdjusterInputBlur = (evt: React.FocusEvent<HTMLInputElement>): void => {
    this.setState({
      adjustmentAmount: HelperUtils.parseInputInt(evt.target.value),
      isAdjusterFocused: false,
    });
  };

  handleAdjusterInputFocus = (
    evt: React.FocusEvent<HTMLInputElement>
  ): void => {
    this.setState({
      isAdjusterFocused: true,
    });
  };

  handleAdjusterHitPointsUpdate = (adjustmentDirection: number): void => {
    const { dispatch, hitPointInfo } = this.props;
    const { adjustmentAmount } = this.state;

    const hpDiff = adjustmentDirection * (adjustmentAmount ?? 0);

    const { newTemp, newRemovedHp } = CharacterUtils.calculateHitPoints(
      hitPointInfo,
      hpDiff
    );

    dispatch(characterActions.hitPointsSet(newRemovedHp, newTemp));
    this.setState({
      adjustmentAmount: null,
      isAdjusterHovered: false,
    });
  };

  handleAdjusterHealClick = (): void => {
    const { isReadonly } = this.props;

    if (!isReadonly) {
      this.handleAdjusterHitPointsUpdate(1);
    }
  };

  handleAdjusterDamageClick = (): void => {
    const { isReadonly } = this.props;

    if (!isReadonly) {
      this.handleAdjusterHitPointsUpdate(-1);
    }
  };

  handleCurrentClick = (evt: React.MouseEvent): void => {
    const { isReadonly } = this.props;

    if (!isReadonly) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      this.setState(
        {
          isCurrentEditorVisible: true,
        },
        () => {
          if (this.currentEditorRef.current) {
            this.currentEditorRef.current.focus();
          }
        }
      );
    }
  };

  handleCurrentInputChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({
      currentAmount: HelperUtils.parseInputInt(evt.target.value),
    });
  };

  handleCurrentInputClick = (evt: React.MouseEvent): void => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
  };

  handleCurrentInputBlur = (evt: React.FocusEvent<HTMLInputElement>): void => {
    const { hitPointInfo, dispatch } = this.props;

    let currentValue: number | null = HelperUtils.parseInputInt(
      evt.target.value
    );
    if (currentValue === null) {
      currentValue = hitPointInfo.remainingHp;
    } else {
      let newRemovedAmount = hitPointInfo.totalHp - currentValue;
      newRemovedAmount = HelperUtils.clampInt(
        newRemovedAmount,
        0,
        hitPointInfo.totalHp
      );
      currentValue = hitPointInfo.totalHp - newRemovedAmount;
      if (newRemovedAmount !== hitPointInfo.removedHp) {
        dispatch(
          characterActions.hitPointsSet(
            newRemovedAmount,
            hitPointInfo.tempHp ?? HP_TEMP_VALUE.MIN
          )
        );
      }
    }
    this.setState({
      currentAmount: currentValue,
      isCurrentEditorVisible: false,
    });
  };

  handleTempClick = (evt: React.MouseEvent): void => {
    const { isReadonly } = this.props;

    if (!isReadonly) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      this.setState(
        {
          isTempEditorVisible: true,
        },
        () => {
          if (this.tempEditorRef.current) {
            this.tempEditorRef.current.focus();
          }
        }
      );
    }
  };

  handleTempInputChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      tempAmount: HelperUtils.parseInputInt(evt.target.value),
    });
  };

  handleTempInputClick = (evt: React.MouseEvent): void => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
  };

  handleTempInputBlur = (evt: React.FocusEvent<HTMLInputElement>): void => {
    const { hitPointInfo, dispatch } = this.props;

    let tempValue: number | null = HelperUtils.parseInputInt(evt.target.value);
    if (tempValue === null) {
      tempValue = hitPointInfo.tempHp;
    } else {
      tempValue = HelperUtils.clampInt(
        tempValue,
        HP_TEMP_VALUE.MIN,
        HP_TEMP_VALUE.MAX
      );
      if (tempValue !== hitPointInfo.tempHp) {
        dispatch(
          characterActions.hitPointsSet(hitPointInfo.removedHp, tempValue)
        );
      }
    }
    this.setState({
      tempAmount: tempValue,
      isTempEditorVisible: false,
    });
  };

  renderAdjustEditor = (): React.ReactNode => {
    const { adjustmentAmount } = this.state;
    const { isReadonly, theme } = this.props;

    return (
      <div
        className={`ct-health-summary__adjuster ${
          theme.isDarkMode ? "ct-health-summary__adjuster--dark-mode" : ""
        }`}
        onClick={this.handleAdjusterClick}
        onMouseOver={this.handleAdjusterMouseOver}
        onMouseOut={this.handleAdjusterMouseOut}
      >
        <div className="ct-health-summary__adjuster-action ct-health-summary__adjuster-action--heal">
          <ThemeButton
            size="small"
            style="outline"
            block={true}
            className="ct-health-summary__adjuster-button ct-health-summary__adjuster-button--heal"
            onClick={this.handleAdjusterHealClick}
            isInteractive={!isReadonly}
          >
            Heal
          </ThemeButton>
        </div>
        <div className="ct-health-summary__adjuster-field">
          <input
            className="ct-theme-input ct-health-summary__adjuster-field-input"
            type="number"
            aria-label="Health"
            value={adjustmentAmount ?? ""}
            min={HP_DAMAGE_TAKEN_VALUE.MIN}
            max={HP_DAMAGE_TAKEN_VALUE.MAX}
            onFocus={this.handleAdjusterInputFocus}
            onChange={this.handleAdjusterInputChange}
            onBlur={this.handleAdjusterInputBlur}
            readOnly={isReadonly}
          />
        </div>
        <div className="ct-health-summary__adjuster-action ct-health-summary__adjuster-action--damage">
          <ThemeButton
            size="small"
            style="outline"
            block={true}
            className="ct-health-summary__adjuster-button ct-health-summary__adjuster-button--damage"
            onClick={this.handleAdjusterDamageClick}
            isInteractive={!isReadonly}
          >
            Damage
          </ThemeButton>
        </div>
      </div>
    );
  };

  renderAdjustEmpty = (): React.ReactNode => {
    return (
      <div
        className="ct-health-summary__hp-item ct-health-summary__hp-item--adjust"
        onClick={this.handleAdjusterEmptyClick}
      >
        <div className="ct-health-summary__hp-item-label">Adjust</div>
        <div className="ct-health-summary__hp-item-content">
          <div className="ct-health-summary__hp-adjustment-icon" />
        </div>
      </div>
    );
  };

  renderTempHitPointsEditor = (): React.ReactNode => {
    const { tempAmount } = this.state;

    return (
      <input
        ref={this.tempEditorRef}
        className="ct-health-summary__hp-item-input ct-theme-input"
        type="number"
        value={tempAmount ?? ""}
        min={HP_TEMP_VALUE.MIN}
        max={HP_TEMP_VALUE.MAX}
        onChange={this.handleTempInputChange}
        onBlur={this.handleTempInputBlur}
        onClick={this.handleTempInputClick}
      />
    );
  };

  renderTempHitPoints = (): React.ReactNode => {
    const { isTempEditorVisible, tempAmount } = this.state;
    const { theme } = this.props;

    if (isTempEditorVisible) {
      return this.renderTempHitPointsEditor();
    }

    if (!tempAmount) {
      return (
        <div
          aria-label="No temporary hit points"
          className={`ct-health-summary__hp-empty ${
            theme.isDarkMode ? "ct-health-summary__hp-empty--dark-mode" : ""
          }`}
          onClick={this.handleTempClick}
        >
          --
        </div>
      );
    }

    return (
      <div
        aria-labelledby="ct-health-summary-temp-label ct-health-summary-label"
        className={`ct-health-summary__hp-number ${
          theme.isDarkMode ? "ct-health-summary__hp-number--dark-mode" : ""
        }`}
        onClick={this.handleTempClick}
      >
        {tempAmount}
      </div>
    );
  };

  renderHitPointSummary = (): React.ReactNode => {
    const { isAdjusterEditorVisible, isCurrentEditorVisible, currentAmount } =
      this.state;
    const { hitPointInfo, theme } = this.props;

    return (
      <div className={"ct-health-summary__hp"}>
        <div className="ct-health-summary__hp-group ct-health-summary__hp-group--adjust">
          {isAdjusterEditorVisible
            ? this.renderAdjustEditor()
            : this.renderAdjustEmpty()}
        </div>
        <div className="ct-health-summary__hp-group ct-health-summary__hp-group--primary">
          <div className="ct-health-summary__hp-item">
            <div
              id="ct-health-summary-current-label"
              className={`ct-health-summary__hp-item-label ${
                theme.isDarkMode
                  ? "ct-health-summary__hp-item-label--dark-mode"
                  : ""
              }`}
            >
              Current
            </div>
            <div className="ct-health-summary__hp-item-content">
              {isCurrentEditorVisible ? (
                <input
                  ref={this.currentEditorRef}
                  className="ct-theme-input ct-health-summary__hp-item-input"
                  type="number"
                  value={currentAmount ?? ""}
                  min={0}
                  max={hitPointInfo.totalHp}
                  onChange={this.handleCurrentInputChange}
                  onBlur={this.handleCurrentInputBlur}
                  onClick={this.handleCurrentInputClick}
                />
              ) : (
                <div
                  aria-labelledby="ct-health-summary-current-label ct-health-summary-label"
                  className={`ct-health-summary__hp-number ${
                    theme.isDarkMode
                      ? "ct-health-summary__hp-number--dark-mode"
                      : ""
                  }`}
                  onClick={this.handleCurrentClick}
                >
                  {currentAmount}
                </div>
              )}
            </div>
          </div>
          <div className="ct-health-summary__hp-item">
            <div className="ct-health-summary__hp-item-label" />
            <div className="ct-health-summary__hp-item-content">
              <span className="ct-health-summary__hp-sep">/</span>
            </div>
          </div>
          <div className="ct-health-summary__hp-item">
            <div
              id="ct-health-summary-max-label"
              className={`ct-health-summary__hp-item-label ${
                theme.isDarkMode
                  ? "ct-health-summary__hp-item-label--dark-mode"
                  : ""
              }`}
            >
              {" "}
              Max
            </div>
            <div className="ct-health-summary__hp-item-content">
              <div
                aria-labelledby="ct-health-summary-max-label ct-health-summary-label"
                className={`ct-health-summary__hp-number ${
                  theme.isDarkMode
                    ? "ct-health-summary__hp-number--dark-mode"
                    : ""
                }`}
              >
                {hitPointInfo.totalHp}
              </div>
            </div>
          </div>
        </div>
        <div className="ct-health-summary__hp-group ct-health-summary__hp-group--temp">
          <div className="ct-health-summary__hp-item--temp">
            <div
              id="ct-health-summary-temp-label"
              className={`ct-health-summary__hp-item-label ${
                theme.isDarkMode
                  ? "ct-health-summary__hp-item-label--dark-mode"
                  : ""
              }`}
            >
              Temp
            </div>
            <div className="ct-health-summary__hp-item-content">
              {this.renderTempHitPoints()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDeathSavesSummaryMarkGroup = (
    key: string,
    label: string,
    activeCount: number,
    totalCount: number,
    isDarkMode: boolean
  ): React.ReactNode => {
    let marks: Array<React.ReactNode> = [];
    for (let i = 0; i < totalCount; i++) {
      let classNames: Array<string> = [
        "ct-health-summary__deathsaves-mark",
        `ct-health-summary__deathsaves-mark--${
          i < activeCount ? "active" : "inactive"
        }`,
      ];
      if (isDarkMode) {
        classNames.push("ct-health-summary__deathsaves-mark--dark-mode");
      }
      marks.push(<span key={`${key}-${i}`} className={classNames.join(" ")} />);
    }
    const { theme } = this.props;

    return (
      <div
        className={`ct-health-summary__deathsaves-group ct-health-summary__deathsaves--${key}`}
      >
        <span
          className={`ct-health-summary__deathsaves-label ${
            theme.isDarkMode
              ? "ct-health-summary__deathsaves-label--dark-mode"
              : ""
          }`}
        >
          {label}
        </span>
        <span className="ct-health-summary__deathsaves-marks">{marks}</span>
      </div>
    );
  };

  renderDeathSavesSummary = (): React.ReactNode => {
    const {
      fails,
      successes,
      ruleData,
      deathsavesFailLabel,
      deathsavesSuccessLabel,
      theme,
    } = this.props;

    return (
      <div className="ct-health-summary__data ct-health-summary__deathsaves">
        <div
          className={`ct-health-summary__deathsaves-icon ${
            theme.isDarkMode
              ? "ct-health-summary__deathsaves-icon--dark-mode"
              : ""
          }`}
        />
        <div className="ct-health-summary__deathsaves-content">
          {this.renderDeathSavesSummaryMarkGroup(
            "fail",
            deathsavesFailLabel,
            fails,
            RuleDataUtils.getMaxDeathsavesFail(ruleData),
            theme.isDarkMode
          )}
          {this.renderDeathSavesSummaryMarkGroup(
            "success",
            deathsavesSuccessLabel,
            successes,
            RuleDataUtils.getMaxDeathsavesSuccess(ruleData),
            theme.isDarkMode
          )}
        </div>
      </div>
    );
  };

  renderDeathExhaustionSummary = (): React.ReactNode => {
    const { theme } = this.props;
    return (
      <div className="ct-health-summary__data ct-health-summary__exhaustion">
        <div
          className={`ct-health-summary__exhaustion-icon ${
            theme.isDarkMode
              ? "ct-health-summary__exhaustion-icon--dark-mode"
              : ""
          }`}
        />
        <div className="ct-health-summary__exhaustion-content">
          <span className="ct-health-summary__exhaustion-label">
            Exhaustion Level 6
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { hitPointInfo, deathCause, theme } = this.props;

    let healthSummaryNode: React.ReactNode;
    let healthSummaryLabel: string;

    if (deathCause === Constants.DeathCauseEnum.CONDITION) {
      healthSummaryNode = this.renderDeathExhaustionSummary();
      healthSummaryLabel = "Death";
    } else if (hitPointInfo.remainingHp <= 0) {
      healthSummaryNode = this.renderDeathSavesSummary();
      healthSummaryLabel = "Death Saves";
    } else {
      healthSummaryNode = this.renderHitPointSummary();
      healthSummaryLabel = "Hit Points";
    }

    return (
      <div className="ct-health-summary" onClick={this.handleSummaryClick}>
        <BoxBackground StyleComponent={BeveledBoxSvg317x89} theme={theme} />
        {healthSummaryNode}
        <span
          id="ct-health-summary-label"
          className={`ct-health-summary__label ${
            theme.isDarkMode ? "ct-health-summary__label--dark-mode" : ""
          }`}
        >
          {healthSummaryLabel}
        </span>
      </div>
    );
  }
}

function mapStateToProps(state: SheetAppState) {
  return {
    hitPointInfo: rulesEngineSelectors.getHitPointInfo(state),
    fails: rulesEngineSelectors.getDeathSavesFailCount(state),
    successes: rulesEngineSelectors.getDeathSavesSuccessCount(state),
    ruleData: rulesEngineSelectors.getRuleData(state),
    isReadonly: appEnvSelectors.getIsReadonly(state),
    deathCause: rulesEngineSelectors.getDeathCause(state),
    theme: rulesEngineSelectors.getCharacterTheme(state),
  };
}

const HealthSummaryContainer = (props) => {
  const {
    pane: { paneHistoryStart },
  } = useSidebar();
  return <HealthSummary paneHistoryStart={paneHistoryStart} {...props} />;
};

export default connect(mapStateToProps)(HealthSummaryContainer);
