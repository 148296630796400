import clsx from "clsx";
import { FC, HTMLAttributes, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tooltip } from "@dndbeyond/character-common-components/es";
import { CharacterAvatarPortrait } from "@dndbeyond/character-components/es";
import ShuffleIcon from "@dndbeyond/fontawesome-cache/svgs/solid/shuffle.svg";
import { LabelChip } from "@dndbeyond/ttui/components/LabelChip";

import { Button } from "~/components/Button/Button";
import {
  CharacterNameLimitMsg,
  InputLimits,
  DefaultCharacterName,
} from "~/constants";
import { useCharacterEngine } from "~/hooks/useCharacterEngine";
import { modalActions } from "~/tools/js/Shared/actions";
import { FormInputField } from "~/tools/js/Shared/components/common/FormInputField";

import { builderActions } from "../../../../tools/js/CharacterBuilder/actions";
import { builderSelectors } from "../../../../tools/js/CharacterBuilder/selectors";
import styles from "./styles.module.css";

interface Props extends HTMLAttributes<HTMLDivElement> {
  hidePortrait?: boolean;
  handleNameUpdate?: (newValue: string) => void;
  fullWidth?: boolean;
  useDefaultCharacterName?: boolean;
}

export const PortraitName: FC<Props> = ({
  fullWidth,
  hidePortrait,
  handleNameUpdate,
  useDefaultCharacterName,
  ...props
}) => {
  const dispatch = useDispatch();
  const { characterActions, decorationUtils, decorationInfo, characterName } =
    useCharacterEngine();
  const [localValue, setLocalValue] = useState(characterName);

  const suggestedNames = useSelector(builderSelectors.getSuggestedNames);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentSuggestedNames, setCurrentSuggestedNames] = useState<
    Array<string>
  >([]);

  useEffect(() => {
    setCurrentSuggestedNames(suggestedNames);
  }, [suggestedNames]);

  const handleNameFieldSet = (value: string | null): void => {
    let updatedName = value ?? "";

    // If the flag useDefaultCharacterName is true and value is falsy, set the name to the DefaultCharacterName
    if (useDefaultCharacterName) updatedName = value || DefaultCharacterName;

    setLocalValue(updatedName);
    if (handleNameUpdate) {
      handleNameUpdate(updatedName);
    } else {
      dispatch(characterActions.nameSet(updatedName));
    }
  };

  const handleShowSuggestions = (): void => {
    if (currentSuggestedNames.length === 0) {
      dispatch(builderActions.suggestedNamesRequest());
    }

    setShowSuggestions(!showSuggestions);
  };

  const handleSuggestMoreClick = (): void => {
    dispatch(builderActions.suggestedNamesRequest());
  };

  const handleAvatarEdit = (_event: React.MouseEvent): void => {
    dispatch(modalActions.open("avatar"));
  };

  const avatarUrl = decorationUtils.getAvatarInfo(decorationInfo).avatarUrl;

  return (
    <div {...props}>
      <div
        className={clsx([styles.nameContainer, fullWidth && styles.fullwidth])}
      >
        {!hidePortrait && (
          <div onClick={handleAvatarEdit}>
            <CharacterAvatarPortrait
              className={clsx([styles.avatar, !avatarUrl && styles.empty])}
              avatarUrl={avatarUrl}
            />
          </div>
        )}
        <div className={styles.inputContainer}>
          <FormInputField
            label="Character Name"
            onBlur={handleNameFieldSet}
            initialValue={localValue}
            inputAttributes={
              {
                spellCheck: false,
                autoComplete: "off",
              } as HTMLAttributes<HTMLInputElement>
            }
            maxLength={InputLimits.characterNameMaxLength}
            maxLengthErrorMsg={CharacterNameLimitMsg}
          />
          <Button
            className={styles.suggestionButton}
            onClick={handleShowSuggestions}
            size="xx-small"
            variant="text"
            forceThemeMode="light"
          >
            {showSuggestions ? "Hide" : "Show"} Suggestions
          </Button>
        </div>
      </div>
      {showSuggestions && (
        <div>
          <div className={styles.suggestionsContainer}>
            <div className={styles.suggestionsLabel}>Suggestions:</div>
            <div>
              <Tooltip title="Shuffle Suggestions">
                <Button
                  variant="text"
                  size="xx-small"
                  onClick={handleSuggestMoreClick}
                  className={styles.shuffleButton}
                >
                  <ShuffleIcon className={styles.shuffleIcon} />
                </Button>
              </Tooltip>
            </div>
            <div className={styles.suggestions}>
              {currentSuggestedNames.map((name: string, index: number) => (
                <LabelChip
                  key={index}
                  onClick={() => handleNameFieldSet(name)}
                  className={styles.suggestion}
                >
                  {name}
                </LabelChip>
              ))}
            </div>
          </div>
          <div className={styles.credit}>
            Names by
            <Button
              className={styles.creditLink}
              href="http://www.fantasynamegenerators.com/"
              target="_blank"
              rel="noreferrer"
              variant="text"
            >
              <span className={styles.creditText}>Fantasy Name Generators</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
