export const QUICK_BUILD_REQUEST = "builder.QUICK_BUILD_REQUEST";
export const RANDOM_BUILD_REQUEST = "builder.RANDOM_BUILD_REQUEST";
export const STEP_BUILD_REQUEST = "builder.STEP_BUILD_REQUEST";
export const CHARACTER_LOAD_REQUEST = "builder.CHARACTER_LOAD_REQUEST";
export const BUILDER_METHOD_SET = "builder.BUILDER_METHOD_SET";
export const BUILDER_METHOD_SET_COMMIT = "builder.BUILDER_METHOD_SET_COMMIT";
export const CHARACTER_LOADING_SET_COMMIT =
  "builder.CHARACTER_LOADING_SET_COMMIT";
export const CHARACTER_LOADED_SET_COMMIT =
  "builder.CHARACTER_LOADED_SET_COMMIT";
export const CONFIRM_SPECIES_SET = "builder.CONFIRM_SPECIES_SET";
export const CONFIRM_CLASS_SET = "builder.CONFIRM_CLASS_SET";
export const SHOW_HELP_TEXT_SET = "builder.SHOW_HELP_TEXT_SET";
export const SUGGESTED_NAMES_SET = "builder.SUGGESTED_NAMES_SET";
export const SUGGESTED_NAMES_REQUEST = "builder.SUGGESTED_NAMES_REQUEST";
